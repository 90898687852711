// Initialize and add the map
let map;

const SVG_MARKER = `
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="30" cy="30" r="30" fill="#F04123" style="fill:#F04123;fill:color(display-p3 0.9412 0.2549 0.1373);fill-opacity:1;"/>
  <path d="M35.3834 21.3757C34.6082 20.7112 33.9659 20.2461 32.349 20.2461H21.4299C21.4077 20.2461 21.3856 20.2904 21.4077 20.3347L32.2383 28.5739C32.8584 29.0612 33.2571 29.5041 33.2571 30.1021C33.2571 30.4344 33.1242 30.7887 32.6591 31.121C32.6148 31.1431 32.637 31.1653 32.6591 31.1653H37.4432C41.7178 31.1653 42.4044 30.6558 42.6037 30.5008C42.6259 30.4787 42.6923 30.4565 42.7145 30.4787C42.7366 30.5008 42.7145 30.5672 42.6923 30.5894C41.895 31.5639 40.5882 32.5606 36.9559 32.5606H29.957C24.3092 32.5606 22.4708 33.5351 16.225 40.2018C16.2028 40.2239 16.225 40.2461 16.2471 40.2461H31.0201C33.8108 40.2461 34.7411 39.6259 36.7344 37.8098L40.6547 34.1996C42.7366 32.2948 43.4897 31.1431 43.4897 29.8363C43.4897 28.3967 42.5594 27.4443 41.4077 26.4476L35.3834 21.3757Z" fill="white" style="fill:white;fill-opacity:1;"/>
</svg>
`;

const MAP_OPTIONS = {
  mapId: 'f35b1a2441437fc0',
  mapTypeControl: false
};

async function initMap(mapContainer) {
  const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  const lat = parseFloat(mapContainer.dataset.lat);
  const lng = parseFloat(mapContainer.dataset.long);
  const zoom = parseFloat(mapContainer.dataset.zoom);
  const content = mapContainer.dataset.content || null;

  const map = new Map(mapContainer, {
    ...MAP_OPTIONS,
    zoom,
    center: { lat, lng }
  });

  if (content && content !== '') {
    const parser = new DOMParser();
    const pinSvg = parser.parseFromString(SVG_MARKER, "image/svg+xml").documentElement;

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat, lng },
      content: pinSvg,
      title: 'test',
      gmpClickable: true
    });

    if (content) {

        const infoWindow = new InfoWindow({ content });
        infoWindow.open(map, marker);

        marker.addListener("click", () => {
        infoWindow.open(map, marker);
        });
    }
  }
}

function initMapElement(mapElement) {
  mapElement.classList.add('map-element-initialized');
  initMap(mapElement);
}

function initMapBlock() {
  const mapElements = document.querySelectorAll('.map-element:not(.map-element-initialized)');
  mapElements.forEach(initMapElement);
}

// Initialize the main map if it exists
const mainMapContainer = document.getElementById("map");
if (mainMapContainer) {
  initMap(mainMapContainer);
}

// Expose functions to the global scope
window.initMapBlock = initMapBlock;
window.initMapElement = initMapElement;
